<template>
  <div class="district-info d-flex flex-column gap-3">
    <div>
      <b v-if="banner.model[0].title" class="district-name">{{ getTitleFirstWord(banner.model[0].title) }}</b>
      <br>
      <b v-if="banner.model[0].title" class="district-name">{{ getRemainingWords(banner.model[0].title) }}</b>
    </div>
    <p class="district-desc">
      {{ banner.model[0].subTitle }}
    </p>
    <span>
      <button class="district-discover" @click="$router.push(banner.model[0].buttonUrl)">İlçeyi Keşfet</button>
    </span>
  </div>
</template>

<script>

export default {
  name: 'DistrictInfoComponent',
  props: {
    banner: { type: Object }
  },

  methods: {
    getTitleFirstWord(title) {
      return title ? title.split(' ')[0].toLocaleUpperCase('tr') : '';
    },
    getRemainingWords(title) {
      const words = title ? title.split(' ').slice(1).join(' ') : '';
      return words ? words.toLocaleUpperCase('tr') : '';
    }
  },
}
</script>

<style scoped>
.district-info .district-name {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 4px;
}

.district-info .sub-title {
  font-size: 32px;
  letter-spacing: 3px;
}

.district-info .district-desc {
  font-weight: 200;
  max-width: 500px;
  width: 100%;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.district-info .district-discover {
  background-color: #00A8FF;
  color: white;
  padding: 12px 24px;
  border-radius: 20px;
  border: none;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  transition: 300ms all;
  box-shadow: 0 0 0 3px hsl(0deg 0% 100% / 20%);
}

.district-info .district-discover:hover {
  background-color: #b22727;
}

@media screen and (max-width: 576px) {
  .district-info {
    padding-top: 80px;

    .district-name {
      font-size: 40px;
      line-height: 48px;
    }

    .sub-title {
      font-size: 26px;
      letter-spacing: 3px;
    }

    .district-desc {
      font-size: 14px;
      line-height: 20px;
    }

    .district-discover {
      font-size: 14px;
      padding: 10px 20px;
    }
  }
}
</style>