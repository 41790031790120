<template>
  <div id="announcements" class="announcements">

    <div class="anno-item fix">DUYURU</div>

    <!-- :loop="true" -->
    <Swiper :modules="modules" :direction="'vertical'" :navigation="true" :slides-per-view="1" :autoplay="{ delay: 3000 }" >
      <SwiperSlide v-for="(item, index) in announcements" :key="index">
        <div class="anno-item" @click="goSelectAnnouncement(item)">{{ item.name }}</div>
      </SwiperSlide>
    </Swiper>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import apiService from "@/services/service";

import "swiper/css";
import 'swiper/css/navigation';

export default {
  name: 'AnnouncementsComponent',
  components: {
    Swiper,
    SwiperSlide,
  },

  created() {
    this.getAnnouncements();
  },

  methods: {
    getAnnouncements() {
      apiService.getHomeData("Announcements", { pageIndex: 1, pageSize: 10 })
        .then(res => {
          if (res.success === true) {
            this.announcements = res.model;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    goSelectAnnouncement(item) {
      this.$router.push(item.url);
    }

  },

  data() {
    return {
      modules: [Autoplay, Pagination, Navigation],
      announcements: {}
    };
  },
}
</script>

<style scoped>
.announcements {
  /* position: relative; */
  z-index: 2;
  margin: 20px 0;
  width: 100%;
  height: 70px;
  display: flex;
  overflow: hidden;
  background-color: #5E6666;
}

.anno-item {
  background-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  padding: 10px 30px;
  transition: background-color 300ms;
  font-weight: 600;
  height: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.anno-item:hover {
  cursor: pointer;
}

.anno-item:not(.fix) {
  padding: 10px 10px;
}

.anno-item.fix {
  background-color: #2C5550;
  font-size: 18px;
  letter-spacing: 1px;
}

.swiper {
  width: 100%;
}
.swiper-slide {
  padding-left: 50px;
}


@media screen and (max-width: 767px) {
  .announcements {
    display: flex;
    flex-direction: column;
    height: 100px;
  }

  .anno-item {
    padding: 20px !important;
    height: 50px;
    font-size: 14px;
  }
}
@media screen and (max-width: 576px) {
  .anno-item {
    font-size: 12px;
  }
}
</style>