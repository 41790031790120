<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Nöbetçi Eczane</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="info">
              <div v-html="pharmacy.html"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '@/services/service';

export default {
  name: 'PharmacyComponent',

  created() {
    this.getPharmacy();
  },
  methods: {
    getPharmacy() {
      apiService.getHomeData("Pharmacy")
        .then(res => {
          this.pharmacy = res.model;
        })
        .catch(error => {
          console.error(error);
        });
    }
  },

  data() {
    return {
      pharmacy: {}
    }
  },

}
</script>

<style lang="scss" scoped>
.modal-dialog {
  width: 430px;

}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.modal-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.btn-close {
  background-color: #fff;
  margin: unset !important;
}

.info {
  margin: auto;
  text-align: center;
  width: 100%;
}


@media screen and (max-width: 576px) {

  .modal-dialog {
    max-width: 100%;
    margin: 10px auto;
  }
}
</style>