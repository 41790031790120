import axios from 'axios';


class ApiService {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.VUE_APP_USER_FRONTEND,
    });
  }

  async getPageApiData(endpoint, payload = {}) {
    let url = `/${endpoint}`;

    if (Object.keys(payload).length > 0) {
      url += `?${Object.entries(payload)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`;
    }

    try {
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getHomeData(api, payload = {}) {
    let url = `/home/${api}`;

    if (Object.keys(payload).length > 0) {
      url += `?${Object.entries(payload)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`;
    }

    try {
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getGalleryData(api) {
    let url = `/Gallery/${api}`;

    try {
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSearchData(query, payload = {}) {
    let url = `/Search/${query}`;
  
    // const queryParams = {
    //   pageIndex: payload.pageIndex,
    //   pageSize: payload.pageSize,
    // };
  
    // queryParams nesnesini dizeye dönüştürerek URL'ye ekleyin
    const queryString = Object.entries(payload)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  
    if (queryString) {
      url += `?${queryString}`;
    }
  
    try {
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  
}

const apiService = new ApiService();

export default apiService;
