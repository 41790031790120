<template>
  <div class="news-container">
    <div class="left">
      <Swiper :loop="false" :modules="modules" :direction="'horizontal'" :pagination="{ clickable: true }"
        :autoplay="{ delay: 3000 }">
        <SwiperSlide v-for="(item, index) in news" :key="index" @click="goSelectNew(item)">
          <img :src="item.fileUrl" alt="">
          <div class="desc">
            <div> {{ item.name }}</div>
            <a class="all-new-btn" @click.stop="goRoutePage('haberler')">Tüm Haberler</a>
          </div>
        </SwiperSlide>
      </Swiper>

    </div>
    <div class="right">
      <div class="second">
        <img src="../assets/images/image2.jpg" alt="">

        <div class="widget">
          <a href="https://www.facebook.com/PazarBld"
            target="_blank">
            <div class="widget-item death-news">
              <span class="icon">
                <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.05 444.3c-9.626 10.87-7.501 27.62 4.5 35.75l68.76 27.87c9.876 6.75 23.38 4.1 31.38-3.75l91.76-101.9L123.2 314.3L6.05 444.3zM441.8 444.3c0 0-292-324.5-295.4-329.1c15.38-8.5 40.25-17.1 77.51-17.1s62.13 9.5 77.51 17.1c-3.25 5.5-56.01 64.5-56.01 64.5l79.13 87.75l34.13-37.1c28.75-31.87 33.38-78.62 11.5-115.5L326.5 39.52c-4.25-7.25-9.876-13.25-16.75-17.1c-40.75-27.62-127.5-29.75-171.5 0C131.3 26.27 125.7 32.27 121.4 39.52L77.81 112.8C76.31 115.3 40.68 174.9 89.31 228.8l248.1 275.2c8.001 8.875 21.38 10.5 31.25 3.75l68.88-27.87C449.5 471.9 451.6 455.1 441.8 444.3z" />
                </svg>
              </span>
              VEFAT HABERLERİ
            </div>
          </a>
          <div class="widget-item announcements" @click="goRoutePage('duyurular')">
            <span class="icon">
              <svg>
                <use :xlink:href="require('@/assets/svg/common.svg') + '#anons-icon'"></use>
              </svg>
            </span>
            DUYURULAR
          </div>
          <div class="widget-item pharmacies-duty" data-bs-toggle="modal" data-bs-target="#exampleModal">
            <span class="icon">
              <svg>
                <use :xlink:href="require('@/assets/svg/common.svg') + '#pharmacy-icon'"></use>
              </svg>
            </span>
            NÖBETÇİ ECZANE

          </div>
          <pharmacy-component />
        </div>

      </div>
      <div class="third">
        <div class="image-gallery">
          <img :src="imageGallery" alt="" @click="$router.push('foto-galeri')">
          <div class="image-gallery-title">Fotoğraf Galeri</div>
        </div>
        <div class="video-gallery">
          <img :src="videoGallery" alt="" @click="$router.push('video-galeri')">
          <div class="video-gallery-title">Video Galeri</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import apiService from "@/services/service";
import PharmacyComponent from '@/components/modal/PharmacyComponent.vue';

import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'NewsComponent',
  components: {
    Swiper,
    SwiperSlide,
    "pharmacy-component": PharmacyComponent
  },
  created() {
    this.getNews();
    this.getImageGallery();
    this.getVideoGallery();
  },

  methods: {
    getNews() {
      apiService.getHomeData("News", { pageIndex: 1, pageSize: 4 })
        .then(res => {
          this.news = res.model;
        })
        .catch(error => {
          console.error(error);
        });
    },

    goSelectNew(item) {
      this.$router.push(item.url)
    },

    goRoutePage(route) {
      this.$router.push(route);

    },

    getImageGallery() {
      apiService.getHomeData("ImageGallery")
        .then(res => {
          this.imageGallery = res.model.fileUrl;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getVideoGallery() {
      apiService.getHomeData("VideoGallery")
        .then(res => {
          this.videoGallery = res.model.fileUrl;
        })
        .catch(error => {
          console.error(error);
        });
    },
    showModal() {
      this.modalOpen = true;
    },
    hideModal() {
      this.modalOpen = false;
    },
  },

  data() {
    return {
      modules: [Pagination, Navigation, Autoplay],
      news: [],
      imageGallery: "",
      videoGallery: "",
      modalOpen: false,
    };
  },
}
</script>

<style lang="scss" scoped>
.news-container {
  padding: 0 40px;
  margin-bottom: 40px;
  display: flex;
  gap: 30px;
}

.news-container img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.news-container .left {
  position: relative;
  flex: 15;
  min-width: 0;
}

.news-container .right {
  flex: 10;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.news-container .right .third {
  display: flex;
  // grid-area: 30px;
  gap: 10px;
}

.news-container .right .third .image-gallery,
.news-container .right .third .video-gallery {
  flex: 1;
  position: relative;
}

.news-container .right .third .image-gallery .image-gallery-title,
.news-container .right .third .video-gallery .video-gallery-title {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #fff;
  font-weight: 500;
}

.news-container .right .third img {
  cursor: pointer;
}

.news-container ul.news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 40px;
  top: 0;
  width: 10px;
  height: 100%;
  gap: 20px;
}

.news-container ul.news li.new-item {
  width: 10px;
  height: 10px;
  background-color: #fff;
}

.news-container ul.news li.new-item:hover {
  cursor: pointer;
}

.news-container ul.news li.new-item.active {
  background: #00A8FF;
}

.news-container .desc {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 500;
  font-size: 18px;
  background-color: #00A8FF;
  color: #fff;
  /* margin: 0 12px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  text-wrap: nowrap;

  .desc-txt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.news-container .desc .all-new-btn {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: 300ms all;
  padding: 0 10px;
  height: 100%;
  display: grid;
  place-content: center;
  height: 100%;
}

.news-container .desc .all-new-btn:hover {
  background-color: #fff;
  color: #00A8FF;
}

.news-container .right .widget {
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.news-container .right .second {
  position: relative;
}

.news-container .right .widget .widget-item {
  height: 36px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
}

.news-container .right .widget .widget-item:hover {
  cursor: pointer;
}

.news-container .right .widget .widget-item .icon {
  height: 100%;
  width: 36px;
  display: flex;
  align-items: center;
}

.news-container .right .widget .widget-item .icon svg {
  fill: #fff;
  width: 18px;
  height: 18px;
}

@media (max-width: 1180px) {
  .news-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .news-container {
    padding: 0 20px;
    margin-bottom: 20px;
    gap: 10px;
  }


  .news-container .right .third .image-gallery .image-gallery-title,
  .news-container .right .third .video-gallery .video-gallery-title {
    font-size: 12px;
  }

  .news-container .right .widget .widget-item {
    font-size: 12px;
  }

  .news-container .right .widget .widget-item .icon {
    width: 30px;
  }

  .news-container .desc {
    font-size: 12px;
    height: 40px;
    padding-left: 16px;
  }

  .news-container .desc .all-new-btn {
    font-size: 12px;
  }
}

.swiper {
  height: 100% !important;
}

.swiper-wraper {
  height: 500px;
  max-height: 500px;
}

.swiper-slide {
  cursor: pointer;
}
</style>