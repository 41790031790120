<template>
  <div class="district-menu">
    <Swiper swiper-slide-visible="false" :loop="true" :modules="modules" :slides-per-view="menuItemCount"
      :navigation="true" :breakpoints="{
        '0': { slidesPerView: menuItemCount >= 1 ? 1 : menuItemCount },
        '350': { slidesPerView: menuItemCount >= 2 ? 2 : menuItemCount },
        '480': { slidesPerView: menuItemCount >= 3 ? 3 : menuItemCount },
        '710': { slidesPerView: menuItemCount >= 4 ? 4 : menuItemCount },
        '870': { slidesPerView: menuItemCount >= 5 ? 5 : menuItemCount },
        '1110': { slidesPerView: menuItemCount >= 6 ? 6 : menuItemCount },
        '1260': { slidesPerView: menuItemCount >= 7 ? 7 : menuItemCount }
      }">
      <SwiperSlide v-for="(menu, index) in menus" :key="index">
        <div class="menu-item" @click="goSelectMenu(menu)">
          <svg class="icon">
            <use :xlink:href="require('@/assets/svg/common.svg') + menu.icon"></use>
          </svg>
          <div class="menu-name">{{ menu.name }}</div>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from 'swiper/modules';
import apiService from "@/services/service"

import "swiper/css";
import 'swiper/css/navigation';


export default {
  name: "DistrictMenuComponent",
  components: {
    Swiper,
    SwiperSlide,
  },

  created() {
    this.getMenus();
  },

  methods: {
    getMenus() {
      // const body = {
      //   pageIndex:this.pageIndex,
      //   pageSize: this.pageSize
      // }
      apiService.getHomeData("IconMenus")
        .then(res => {
          if (res.success === true) {
            this.menus = res.model;
            this.menuItemCount = this.menus.length;
            for (let index = 0; index < this.menuItemCount - 1; index++) {
              this.menus = this.menus.concat(res.model);
            }
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    goSelectMenu(data) {
      if (data.redirectUrl !== "") {
        // window.location.href = data.redirectUrl;
        const newTab = window.open(data.redirectUrl, '_blank');
        newTab.focus();
      }
      else {
        this.$router.push(data.url);

      }
    }
  },

  data() {
    return {
      modules: [Pagination, Navigation],
      menuItemCount: 7,
      pageIndex: 1,
      pageSize: 50,
      menus: {}
    };
  },
};

</script>

<style scoped >
.district-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  /* padding: 0 50px; */
}

.district-menu .menu-item {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  cursor: pointer;
  transition: 300ms all ease-in-out;
  border-radius: 16px;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.district-menu .menu-item:hover {
  background-color: hsl(201.46deg 79.77% 57.92% / 50%);
}

.district-menu .menu-item svg {
  width: 60px;
  height: 60px;
  fill: #fff;
  transition: 300ms all;
}

.district-menu .icon svg {
  width: 60px;
  height: 60px;
  fill: #fff;
  cursor: pointer;
  transition: 300ms all;
}

.district-menu .icon:hover svg {
  fill: #00A8FF;
}

.district-menu .right-icon svg {
  transform: rotate(180deg);
}

.district-menu .menu-item .menu-name {
  text-align: center;
  font-weight: 500;
}


/* Swiper Styles */

/* .swiper {
  padding: 0 80px;
}
.swiper .swiper-wrapper {
  overflow: hidden;
} */

.swiper-slide {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .district-menu {
    margin-top: 80px;
  }
}

@media screen and (max-width: 576px) {
  .district-menu {
    margin-top: 100px;

    .menu-item {

      .icon {
        width: 45px !important;
        height: 45px !important;
      }

      .menu-name {
        font-size: 12px;
      }
    }
  }
}

/* Max Height  Queries */
@media screen and (max-height: 730px) {
  .district-menu {
    margin-top: 30;
  }
}

@media screen and (max-height: 690px) {
  .district-menu {
    margin-top: 0;
  }
}

@media screen and (max-height: 580px) {
  .district-menu {
    margin-top: 0;
  }
}
</style>