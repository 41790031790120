<template>
  <div class="home">
    <section class="bg-container">
      <div class="bg-image">
        <img :src="imageUrl" alt="">
      </div>

      <video autoplay muted loop ref="video" :key="videoUrl" :class="{ 'active': videoUrl != '' }">
        <source :src="videoUrl" type="video/mp4">
      </video>
      <header-component :class="headerClass" />
      <portfolio-component :banner="banner" />
      <div class="flu"></div>
    </section>

    <announcements-component />
    <news-component />
    <footer-component />

  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import AnnouncementsComponent from '@/components/AnnouncementsComponent.vue'
import PortfolioComponent from '@/components/portfolio/PortfolioComponent.vue'
import NewsComponent from '@/components/NewsComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import apiService from "@/services/service"

export default {
  name: 'HomeView',
  components: {
    "header-component": HeaderComponent,
    "announcements-component": AnnouncementsComponent,
    "portfolio-component": PortfolioComponent,
    "news-component": NewsComponent,
    "footer-component": FooterComponent
  },
  created() {
    if (this.$route.name === 'home') {
      document.title = "Anasayfa" + window.PAGETITLE;
      this.headerClass = 'home-header';
    }
    this.getBanner();

    this.selectedBg();
  },

  methods: {
    getBanner() {
      apiService.getHomeData("Banners")
        .then(res => {
          if (res.success === true) {
            this.banner = res;

            this.fileUrls = res.model.map(item => ({
              fileUrl: item.fileUrl,
              typeCode: item.typeCode
            }));

          }
        })
        .catch(error => {
          console.error(error);
        });
    },

    selectedBg() {
      setInterval(() => {
        if (this.fileUrls.length != 0) {
          this.currentIndex = (this.currentIndex + 1) % this.fileUrls.length;
          let currentItem = this.fileUrls[this.currentIndex];

          if (currentItem.typeCode === 1) { // Video
            this.videoUrl = currentItem.fileUrl;
            try{
              //this.$refs.video.src = currentItem?.fileUrl;
            }
            catch(err) {
              console.log(err);
            }
          } else if (currentItem.typeCode === 2) { // photo
            this.imageUrl = currentItem.fileUrl;
            this.videoUrl = "";
          }
        }
      }, 10000);
    }

  },

  data() {
    return {
      banner: {
        model: [
          {
            title: "Pazar"
          }
        ]
      },
      fileUrls: [],
      videoUrl: "",
      imageUrl: require("@/assets/images/background/bg1.jpg"),
      currentIndex: -1
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  font-family: "Roboto", serif;
}

/* Container */
/* .container-fluid {
  padding: 0;
} */

.custom-container {
  padding-left: 80px;
  padding-right: 80px;
  position: relative;
}

@media screen and (max-width: 576px) {
  .custom-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bg-container {
  height: 100dvh;
  max-height: 100svh;
  max-height: -webkit-fill-available;
  max-height: var(--app-height);
}

.bg-image {
  height: 100dvh;
  max-height: 100svh;
  max-height: -webkit-fill-available;
  max-height: var(--app-height);
  max-height: 100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media screen and (max-width: 576px){
  .bg-image img {
    width: 100%;
    height: 100%;
    animation: zoomBg 20s infinite;
  }
}


.bg-image img {
  width: 100%;
  height: 100%;
  animation: zoomBg 20s infinite;
}


@keyframes zoomBg {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;

  height: 100dvh;
  max-height: 100svh;
  max-height: -webkit-fill-available;
  max-height: var(--app-height);

  opacity: 0;

  transition: 2s opacity;
}

video.active {
  opacity: 1;
}


.flu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .33;
  z-index: 0;

  height: 100dvh;
  max-height: 100svh;
  max-height: -webkit-fill-available;
  max-height: var(--app-height);
}
</style>
