<template>
  <div class="header-container">
    <!-- desktop -->
    <div v-if="isLargeScreen" class="desktop">
      <div class="header custom-container">
        <nav class="navbar navbar-expand-lg">
          <div class="container-fluid d-flex justify-content-between">
            <div class="header-item">
              <router-link class="navbar-brand" to="/"><img src="../assets/images/pazarbellogo.png"></router-link>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="header-item collapse navbar-collapse" id="navbarSupportedContent">
              <template v-if="menus.length > 0">
                <ul class="navbar-nav gap-4">
                  <li v-for="(menu, index) in menus" :key="index" class="nav-item">
                    <a v-if="!menu.items.length" class="nav-link" @click="goSelectMenu(menu)">{{ menu.mainTitle }}</a>

                    <a v-else class="nav-link dropdown-toggle"> {{ menu.mainTitle }} </a>

                    <div v-if="menu.items.length > 0" class="dropdown-menu">
                      <div class="container">
                        <div class="row gy-4">
                          <ul>
                            <li v-for="submenu in menu.items" :key="submenu.subtitle">
                              <a @click="goSelectMenu(submenu)">{{ submenu.subTitle }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </template>
            </div>
          </div>
        </nav>

        <div class="search">
          <div class="frm" role="search" :class="{ 'active': isActive }">
            <input class="search-input form-control" type="search" v-model="searchText" @keyup.enter="performSearch">
            <a id="search-btn" class="search-bg" @click="isActive = !isActive"><i
                class="search-icon fas fa-search"></i></a>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile-Tablet Navbar -->
    <div v-if="isSmallScreen" class="menuicon" :class="{ 'd-none': menuIsActive }"><a @click="openMenu">
        <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="butt"
          stroke-linejoin="arcs">
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </a></div>


    <div v-if="isSmallScreen" class="mobile-logo">
      <router-link class="navbar-brand" to="/"><img src="../assets/images/pazarbellogo.png"></router-link>
    </div>
    <div v-if="isSmallScreen" class="mobile-search">
      <div class="search">
        <div class="frm" role="search" :class="{ 'active': isActive }">
          <input class="search-input form-control" type="search" v-model="searchText" @keyup.enter="performSearch">
          <a id="search-btn" class="search-bg" @click="isActive = !isActive"><i class="search-icon fas fa-search"></i></a>
        </div>
      </div>
    </div>

    <div v-if="isSmallScreen" class="mobile">
      <div class="wrapper" :class="{ 'active': menuIsActive }">
        <nav :class="{ 'active': menuIsActive }">
          <input type="checkbox" id="menu" name="menu" class="m-menu__checkbox" v-model="menuIsActive">

          <div class="m-menu">
            <div class="m-menu__header">
              <label class="m-menu__toggle" for="menu">
                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2"
                  stroke-linecap="butt" stroke-linejoin="arcs">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </label>
              <span>MENU</span>
            </div>
            <template v-if="menus.length > 0">
              <ul>
                <li v-for="(menu, index) in menus" :key="index">
                  <label v-if="!menu.items.length" :class="{ 'a-label__chevron': menu.items.length }"
                    @click="goSelectMenu(menu)"> {{ menu.mainTitle }} </label>
                  <label v-else :class="{ 'a-label__chevron': menu.items.length }" :for="'item-' + index">
                    {{ menu.mainTitle }}
                  </label>
                  <input type="checkbox" :id="'item-' + index" :name="'item-' + index" class="m-menu__checkbox" />
                  <div class="m-menu">
                    <div class="m-menu__header">
                      <label class="m-menu__toggle" :for="'item-' + index">
                        <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2"
                          stroke-linecap="butt" stroke-linejoin="arcs">
                          <path d="M19 12H6M12 5l-7 7 7 7" />
                        </svg>
                      </label>
                      <span>{{ menu.mainTitle }}</span>
                    </div>
                    <ul>
                      <li v-for="(submenu, subIndex) in menu.items" :key="subIndex">
                        <label @click="goSelectMenu(submenu)">{{ submenu.subTitle }}</label>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
// import menus from "./json/menus.json"

import apiService from "@/services/service"

export default {
  name: 'HeaderComponent',

  watch: {
    menuIsActive: {
      handler(val) {
        if (val === true) document.body.classList.add("no-overflow");
        else document.body.classList.remove("no-overflow");
      }
    }
  },

  created() {
    document.body.classList.remove("no-overflow");
    this.getMenu();
  },

  mounted() {
    this.checkScreenSize(); // Sayfa yüklendiğinde ekran boyutunu kontrol et
    window.addEventListener("resize", this.checkScreenSize); // Ekran boyutu değiştikçe kontrol et
  },


  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 991;
      this.isLargeScreen = window.innerWidth > 991;
    },

    getMenu() {
      apiService.getHomeData("HeaderMenus")
        .then(res => {
          this.menus = res.model;
        })
        .catch(error => {
          console.error(error);
        });
    },

    goSelectMenu(data) {
      if(this.lastmenuId == data.menuId || !data.items) {
        this.menuIsActive = false;
      }
      this.lastmenuId = data.menuId;

      if (data.redirectUrl !== "") {
        if (data.redirectUrl.includes('http')) {
          const newTab = window.open(data.redirectUrl, '_blank');
          newTab.focus();
        }
        else this.$router.push(data.redirectUrl);
      }
      else {
        if (data.url == "") { this.$router.push('/')}
        else this.$router.push(data.url);
      }
    },

    performSearch() {
      const searchText = this.searchText;
      this.$router.push({ name: 'search', params: { text: searchText } });
    },
    openMenu() {
      this.menuIsActive = true;
    },
  },

  data() {
    return {
      menus: [],
      isSmallScreen: false,
      isLargeScreen: false,
      menuIsActive: false,
      isActive: false,
      lastmenuId: -1,
      searchText: ""
    }
  }
}
</script>

<style lang="scss" scoped>
.home-header {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
}

.menuicon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  height: 40px;
  width: 40px;
  z-index: 2;
  display: flex;
  align-items: center;
}

.home-header .header {
  background: linear-gradient(to bottom, #333, 33%, transparent);
}

.desktop {
  width: 100%;

  .container-fluid {
    padding: 0;
  }

  .navbar {
    width: 100%;
    /* background: transparent; */
    padding-top: 10px;
    box-sizing: border-box;
    position: relative;
  }

  .navbar .navbar-brand {
    display: block;
    height: 100%;
  }

  .navbar-toggler {
    z-index: 1;
    border-color: #fff;
  }

  .navbar .navbar-brand img {
    height: 100px;
  }

  .navbar-nav .nav-item {
    position: relative;
    cursor: pointer;
  }

  .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-weight: 700;
    text-wrap: nowrap;
  }

  .collapse {
    justify-content: center;
  }

  // search start
  .search {
    position: absolute;
    right: 80px;
    top: calc(50% - 20px);
  }

  .search-input {
    border: none;
    outline: none;

  }

  .search-input:focus {
    box-shadow: none;
  }

  .search-bg {
    min-width: 40px;
    width: 40px;
    aspect-ratio: 1;
    background-color: #00A8FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .frm {
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
    overflow: hidden;
    height: 40px;
    /* height: 0; */
    transition: 300ms width ease-in-out;
    width: 40px;
  }

  .frm.active {
    width: 300px;
    max-width: 80vw;
  }

  .search-icon {
    color: white;
  }

  // search end

  .dropdown-menu {
    position: absolute !important;
    left: 0;
    right: 0;
    // margin: 0 auto;
    border: none;
    border-top: 3px solid #007bb9;
    backdrop-filter: saturate(1.8) blur(20px);
    background-color: unset;
    max-width: 1050px;
    // width: 200px;
    width: min-content;
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    padding: 20px 0;
    font-weight: 600;
  }

  .subtitle {
    max-width: fit-content;
    line-height: 1;
    color: whitesmoke;
    padding: 0.75rem 0;
    font-weight: 500;
  }

  .dropdown-menu ul {
    margin-top: 12px;
  }

  .dropdown-menu ul li {
    display: block;
    line-height: 1;
  }

  .dropdown-menu ul li a {
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25;
    padding: 0.5rem 0;
    border: none;
    outline: none;
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
    text-decoration: none;
    white-space: nowrap;
  }

  .dropdown-menu ul li a:hover {
    color: #00A8FF;
  }

}

@media screen and (min-width: 1260px) {
  .collapse {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 992px) {

  .nav-link:hover+.dropdown-menu,
  .dropdown-menu:hover {
    visibility: visible;
    opacity: 1;
  }
}

/* mobile navbar */

.mobile {
  z-index: 2;
  position: relative;

  /* reset */
  * {
    padding: 0;
    margin: 0;
  }

  nav {
    // background-color: #00A8FF;

    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 0 0 10px 0;
    transition: 300ms all;
    // height: 50px;
    // width: 66px;

    &.active {
      transition: 350ms all;
      max-width: 450px;
      width: 100%;
    }

    .m-menu__checkbox {
      display: none;
    }

    label.m-menu__toggle {
      cursor: pointer;
    }

    .m-menu {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 450px;
      width: 100%;
      height: 100%;
      transform: translate3d(-450px, 0, 0);
      transition: 300ms all;
      z-index: 1;
      overflow: hidden;
      background-color: #fff;

      &__overlay {
        background-color: hsla(0, 0%, 40.4%, .5);
        position: absolute;
        top: 0;
        width: 100%;
        bottom: 0;
        z-index: 1;
        display: none;
      }

      &__header {
        padding: 0 16px;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;

        span {
          font-size: 1.2rem;
          font-weight: bold;
          text-align: center;
          width: 100%;
        }
      }

      .m-menu {
        transform: translate3d(480px, 0, 0);
      }

      ul {
        height: 100%;
        overflow-y: auto;

        li {

          a,
          label {
            display: block;
            text-align: left;
            padding: 0 15px;
            line-height: 47px;
            text-decoration: none;
            color: #333;
            cursor: pointer;
            font-size: 1rem;
            border-bottom: 1px solid #e8e8e8;
            position: relative;
          }

          label {
            &.a-label__chevron::after {
              content: '';
              position: absolute;
              display: inline-block;
              height: 10px;
              width: 10px;
              border-color: #333;
              border-style: solid;
              border-width: 1px 1px 0 0;
              transform: rotate(45deg);
              top: 50%;
              margin-top: -5px;
              right: 16px;
            }
          }

          .-invisible {
            border-bottom: 0;
          }

        }
      }
    }

    .m-menu .m-menu label.m-menu__toggle {
      display: flex;
      border-bottom: 0;
      padding: 0;
      box-sizing: content-box;
    }

    /* fade in checked menu */
    .m-menu__checkbox:checked {
      // ~.m-menu__overlay {
      //   display: block;
      // }

      ~.m-menu {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .wrapper {
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    transition: 300ms all;
  }

  .wrapper.active {
    height: 100vh;
    max-width: 450px;
    width: 100vw;
    transition: 300ms all;
  }
}

.mobile-logo {
  position: absolute;
  left: 40px;
  top: 12px;

  img {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 576px) {
  .mobile-logo {
    left: 20px;

    img {
      width: 50px;
      height: 50px;
    }
  }
}


.mobile-search {

  // search start
  .search {
    position: absolute;
    right: 70px;
    top: 18px;
  }

  // @media screen and (max-width:432px) {
  //   .search {
  //     position: absolute;
  //     right: 20px;
  //     top: 60px;
  //   }
  // }

  .search-input {
    border: none;
    outline: none;

  }

  .search-input:focus {
    box-shadow: none;
  }

  .search-bg {
    min-width: 36px;
    width: 36px;
    aspect-ratio: 1;
    background-color: rgba(0, 0, 0, 30%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }

  .frm {
    // background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px;
    overflow: hidden;
    height: 36px;
    /* height: 0; */
    transition: 300ms width ease-in-out;
    width: 36px;
  }

  .frm.active {
    width: 300px;
    max-width: 75vw;
  }

  .search-icon {
    color: white;
  }

  // search end
}

a {
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .frm.active {
    max-width: 62vw !important;
  }
}
</style>