<template>
  <div class="portfolio-container">
    <district-info-component :banner="banner" />
    <district-menu-component />
    <portfolio-footer-component />
  </div>
</template>


<script>
import DistrictInfoComponent from '@/components/portfolio/DistrictInfoComponent.vue'
import DistrictMenuComponent from '@/components/portfolio/DistrictMenuComponent.vue'
import PortfolioFooterComponent from '@/components/portfolio/PortfolioFooterComponent.vue'

export default {
  name: 'PortfolioComponent',
  components: {
    "district-info-component": DistrictInfoComponent,
    "district-menu-component": DistrictMenuComponent,
    "portfolio-footer-component": PortfolioFooterComponent,
  },
  props: ["banner"],

}
</script>

<style scoped>
.portfolio-container {
  position: relative;
  width: 100%;
  /* height: 100%; */
  color: #fff;
  display: flex;
  flex-direction: column;
  
  height: calc(100dvh - 131px);
  justify-content: space-between;
  padding: 100px 80px 160px 80px;
  z-index: 1;
  overflow: hidden;
  gap: 50px;

}

@media screen and (max-width: 991px) {
  .portfolio-container {
    height: 100dvh !important;
    padding: 80px 40px 160px 30px;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 576px) {
  .portfolio-container {
    padding: 80px 20px 95px 20px;
  }
}
@media screen and (max-height: 580px) {
  .portfolio-container {
    gap: 20px;
  }
}
</style>