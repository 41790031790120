<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "@/assets/reset.scss";
@import "@/assets/other.css";

html,
body {
  font-family: "Roboto", serif !important;
}

body.no-overflow {
  height: 100%;
  overflow: hidden;
}

.district-menu {
  .swiper {
    padding-right: 50px;

    .swiper-slide {
      padding: 0 10px;
      // margin-left: -20px;
    }

    .swiper-slide-active {
      margin-left: 30px;
    }

    .swiper-button-next {
      margin-right: -10px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 120px;
      display: flex;
      align-items: center;
      margin-top: -60px;
      color: #fff;
      transition: 300ms all ease-in-out;
    }

    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      color: #00A8FF
    }
  }
}

.announcements {
  .swiper {
    position: relative;

    .swiper-button-prev,
    .swiper-button-next {
      color: #fff !important;
      transform: rotate(90deg) scale(0.5);
    }

    .swiper-button-prev {
      position: absolute;
      top: calc(50% - 15px);
      left: 10px;
    }

    .swiper-button-next {
      position: absolute;
      left: 10px;
      top: calc(50% + 15px);
    }

  }
}

.news-container {
  .swiper-pagination {
    width: auto !important;
    top: 0 !important;
    left: 30px !important;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .swiper-pagination-bullet {
      display: block;
      margin-bottom: 5px !important;
      padding: 7px;
      border-radius: 0;
      background-color: #fff;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: #00A8FF;
    }
  }
}

@media screen and (max-width: 576px) {
  .district-menu {
    .swiper {

      .swiper-button-prev::after,
      .swiper-button-next::after {
        transform: scale(.75);
      }
    }
  }

  .news-container {
    .swiper-pagination {
      margin-bottom: 30px;
      left: 10px !important;
    }
  }
}
</style>
