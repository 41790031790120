<template>
  <div class="portfolio-footer">
    <div class="custom-container">
      <div class="justify">
        <div class="down-arrow">
          <a href="#announcements">
            <svg class="icon">
              <use :xlink:href="require('@/assets/svg/common.svg') + '#two-down-icon'"></use>
            </svg>
          </a>
        </div>
        <div class="weather">
          <div class="cloudy">
            <img src="@/assets/images/cloudy.png" alt="">
          </div>
          <div class="weather-info">
            <div class="wt-top">{{ info.celcius }}&deg; &nbsp;|&nbsp; {{ info.date }}</div>
            <div class="wt-text">{{ info.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/service"

export default {
  name: 'PortfolioFooterComponent',

  created() {
    this.getWeather();
    this.info.date = this.formattedDate;
  },

  computed: {
    formattedDate() {
      const today = new Date();
      return today.toLocaleDateString('tr-TR');
    }
  },

  methods: {
    getWeather() {
      apiService.getHomeData("Weather")
        .then(res => {
          if (res.success === true) {
            this.weather = res.model;

            this.info.description = this.weather.weather[0].description;
            this.info.celcius = parseInt(this.weather.main.temp);
          }
        })
        .catch(error => {
          console.error(error);
        });

    }
  },

  data() {
    return {
      weather: "",

      info: {
        date: "",
        celcius: "",
        description: ""
      }
    }
  },
}


</script>

<style lang="scss" scoped>
.portfolio-footer {
  position: absolute;
  bottom: 20px;
  width: 100%;
  right: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.portfolio-footer .down-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
}

.portfolio-footer .down-arrow svg {
  cursor: pointer;
  width: 50px;
  height: 50px;
  animation: jump 1s ease-in-out infinite;
}

@keyframes jump {
  0%, 20%, 50%, 80%, 100% {
    transform: rotate(270deg) translateX(0);
  }
  40% {
    transform: rotate(270deg) translateX(-10px);
  }
  60% {
    transform: rotate(270deg) translateX(-5px);
  }
}

.portfolio-footer .justify {
  display: flex;
  position: relative;
}

.portfolio-footer .weather {
  position: absolute;
  right: 0;
  height: 50px;
  display: flex;
  gap: 8px;
}

.portfolio-footer .weather .cloudy img {
  height: 50px;
}

.weather-info {
  position: relative;
  display: flex;
  padding-left: 6px;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  height: 100%;
  white-space: nowrap;
}

.portfolio-footer .weather .weather-info::before {
  position: absolute;
  content: "";
  width: 1px;
  left: 0;
  bottom: 0;
  height: 90%;
  background: white;
}

.wt-top {
  font-weight: 200;
}

.portfolio-footer .weather .weather-info .wt-text {
  text-align: left;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .custom-container {
    padding-left: 40px;
    padding-right: 40px;
  }

}

@media screen and (max-width: 576px) {
  .weather {
    display: none !important;
  }

  .portfolio-footer .down-arrow svg {
    width: 40px;
    height: 40px;
  }
}
</style>